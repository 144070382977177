<style scoped>
.handler {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>

<template>
  <div ref="root" :style="control.style">
    <div ref="handler" class="handler" v-show="!isView"></div>
  </div>
</template>

<script>
import echarts from "../charts";
export default {
  props: { control: Object, isView: Boolean },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.change();
  },
  beforeDestroy() {
    if (this.chart) {
      let dom = this.chart.getDom();
      this.chart.dispose();
      if (dom) this.$refs.root.removeChild(dom);
      this.chart = null;
    }
  },
  watch: {
    "control.size": {
      handler() {
        this.change();
      },
      deep: true,
    },
    "control.style": {
      handler() {
        this.change();
      },
      deep: true,
    },
    "control.config": {
      handler() {
        this.change();
      },
      deep: true,
    },
  },
  methods: {
    change() {
      if (!this.chart) {
        let div = document.createElement("div");
        this.$refs.root.insertBefore(div, this.$refs.handler);
        this.chart = echarts.init(div, null, {
          width: this.control.size.width,
          height: this.control.size.height,
        });
      } else {
        this.chart.resize({
          width: this.control.size.width,
          height: this.control.size.height,
        });
      }
      let script = this.control.config.chartOption;
      let option;
      try {
        let Fn = Function;
        script = script.replace(
          /setInterval\(/g,
          "console.warn('setInterval不能出现在绑定脚本中',"
        );
        let fn = new Fn("echarts", script + "\n;return option;");
        option = fn(echarts);
      } catch (e) {
        option = {
          title: {
            text: e.toString(),
          },
        };
      }
      option = option || {};
      this.chart.setOption(option, !this.isView);
    },
  },
};
</script>
